import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/Layout"
import Button from "../components/ui/Button"
import TextLink from "../components/ui/TextLink"
import RibbonText from "../components/ui/RibbonText"
import Testimonial from "../components/ui/Testimonial"
import Seo from "../components/Seo"

export default function Home() {
  return (
    <Layout
      heroBeforeNav={
        <section className="section h-[94vh] sm:h-[90vh] md:h-95vh min-h-0 md:overflow-hidden">
          <div className="wrapper pb-0 h-95vh relative text-white">
            <div className="relative z-10 h-full bg-primary bg-opacity-50">
              <div className="h-full flex flex-col justify-between items-center sm:items-start lg:justify-start px-4 py-8 md:px-8 lg:p-16">
                <h1 className="mb-12 max-w-md !normal-case">
                  Let's Find You a Builder.
                </h1>
                <div className="flex flex-col items-center sm:items-start">
                  <p className="w-2/3 text-center sm:text-left sm:w-56 mb-4">
                    Builder Finders helps you compare and save on your building
                    project.
                  </p>
                  <Button buttonStyle="secondary" to="/what-we-do">
                    What's a Builder Broker?
                  </Button>
                </div>
              </div>
            </div>
            <div className="absolute inset-0 p-4 pb-0">
              <div className="relative w-full h-full">
                <StaticImage
                  src="../images/homepage-hero.jpg"
                  className="w-full h-full"
                  quality={100}
                  alt="A beautiful modern house with a pool and deck lit in a violet sunset."
                />
              </div>
            </div>
          </div>
        </section>
      }
    >
      <Seo title="Home" />
      <section id="time" className="section min-h-0 h-auto">
        <div className="wrapper py-4 sm:py-8 lg:py-24">
          <div className="flex flex-col-reverse sm:grid sm:grid-cols-2 gap-4 lg:gap-16 max-w-3xl mx-auto">
            <div className="flex flex-col justify-between">
              <h2 className="mb-4">
                Time is precious. Let us do the tedious tasks for you.
              </h2>
              <p className="mb-1">
                Outsourcing is an intelligent use of your time and you are still
                always in control. Have professionals who do this daily
                translate your dream project into a course of action, delivering
                the right builders to you for interview. Focus on family,
                friends and work knowing full well we have you covered.
              </p>
              <TextLink to="/what-we-do" className="mb-4 lg:mb-8">
                Learn more about what we do
              </TextLink>
              <RibbonText
                text="streamline your"
                highlighted="project"
                justify="left"
              />
            </div>
            <div className="">
              <StaticImage
                src="../images/bronte-hamptons.jpg"
                className="h-full"
                alt="A classic Hamptons style home."
              />
            </div>
          </div>
        </div>
      </section>
      <section className="section bg-secondary-light">
        <div className="wrapper container py-4 sm:py-8 lg:py-24">
          <div className="grid lg:grid-cols-2 gap-y-8 lg:gap-16">
            <div className="relative">
              <div className="absolute inset-0 pr-10">
                <div className="w-full h-full bg-primary" />
              </div>
              <div className="h-full pl-10 py-4">
                <StaticImage
                  src="../images/builders.jpg"
                  className="h-full"
                  alt="Three builders enjoying their lunch on the roof of their construction site."
                />
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8">
              <div className="sm:col-span-2 flex flex-row justify-between">
                <h2 className="md:w-1/2 lg:w-80">
                  A hand selected panel of expert builders to compare.
                </h2>
                <RibbonText
                  className="hidden sm:inline-block"
                  text="compare"
                  highlighted="builders"
                  justify="right"
                />
              </div>
              <p>
                Deeply vetted and an exclusive selection of builders means you
                are dealing with some of Australia’s leading builders. From
                award winning builders to specialists in both new constructions
                and renovations. We have you covered.
              </p>
              <p>
                We provide a comparison report allowing you to better evaluate
                the builders’ quoted prices. Compare “apples with apples” for a
                deeper understanding of what your money is purchasing.
              </p>
              <div className="sm:col-span-2 flex flex-row justify-end">
                <TextLink to="/what-we-do/compare-the-builders">
                  View our sample report
                </TextLink>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper container overflow-hidden pb-6 sm:pb-8 lg:pb-12">
          <div className="grid sm:grid-cols-3 gap-5">
            <div className="sm:col-span-3 flex flex-col sm:flex-row sm:justify-between space-y-4 sm:space-y-0">
              <h2 className="sm:w-96">
                We’ve helped many secure their building objectives.
              </h2>
              <RibbonText
                className="self-end"
                text="what are people"
                highlighted="saying"
                justify="right"
              />
            </div>
            <Testimonial
              quote="Lynette at Builder Finders has been so helpful, professional and thorough with her knowledge at helping us find a fantastic builder that we trust. Lynette took the information we gave her about the project vision and budget and partnered us with a company that would deliver those visions within our budget ..."
              name="Jen"
              details="Gymea Bay"
            />
            <Testimonial
              quote="We recently used Builder Finders to help us find a builder for our renovation and are happy with the service received. The process from start to finish was easy to navigate with John's assistance. The added bonus of using this service was the connection it has with reputable and quality builders, providing us with the assurance to proceed."
              name="Joselyn"
              details="Dulwich Hill"
            />
            <Testimonial
              quote="We were very 'green' when it comes to how to find a builder for our dream home. Thanks to Lynette, we are now on the way! Development Application has finally been determined & approved, construction certificate approved and our selected builder will be starting very soon. The process was very straightforward ..."
              name="Carl & Cat"
              details="Leura"
            />
            <TextLink
              to="/about-us/testimonials"
              className="sm:col-span-2 md:col-span-1"
            >
              Continue reading testimonials
            </TextLink>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="wrapper container py-6 sm:py-8 lg:py-12">
          <div className="flex flex-col-reverse space-y-reverse space-y-6 sm:grid sm:grid-cols-2 lg:grid-cols-3 sm:gap-16">
            <div className="flex flex-col space-y-4 sm:space-y-8 lg:space-y-0 sm:col-span-2 lg:col-span-1 sm:justify-between sm:order-3 lg:order-none">
              <RibbonText text="featured" highlighted="media" justify="left" />
              <ul className="grid grid-cols-2 lg:grid-cols-1 gap-6 sm:gap-12 lg:w-1/2">
                <li>
                  <StaticImage
                    src="../images/adelaidenow.svg"
                    className="h-8"
                    objectFit="contain"
                  />
                </li>
                <li>
                  <StaticImage
                    src="../images/heraldsun.svg"
                    className="h-8"
                    objectFit="contain"
                  />
                </li>
                <li className="hidden lg:inline">
                  <StaticImage
                    src="../images/the-adviser.svg"
                    className="h-8"
                    objectFit="contain"
                  />
                </li>
                <li>
                  <StaticImage
                    src="../images/smart-property-investment.webp"
                    className="h-8"
                    objectFit="contain"
                  />
                </li>
                <li>
                  <StaticImage
                    src="../images/dailytelegraph.svg"
                    className="h-8"
                    objectFit="contain"
                  />
                </li>
              </ul>
              <TextLink to="/media/press">View more</TextLink>
            </div>
            <div className="flex flex-col items-start">
              <h2 className="mb-2 sm:w-52">How to find the right builder.</h2>
              <div>
                <p className="subtitle w-auto">Lynette Manciameli</p>
                <div className="h-px bg-secondary-light w-full my-2" />
              </div>
              <div className="space-y-1 mb-4 sm:mb-8">
                <p>
                  In this episode of The Smart Property Investment Show, Lynette
                  Manciameli from Builder Finders breaks down her top five tips
                  for finding the right builder for your investment project.
                </p>
                <p>
                  Lynette outlines why there’s often negative rhetoric around
                  builders, how to build good relationships with your builder,
                  and how to mitigate the risk of a building contract going
                  wrong.
                </p>
              </div>
              <div className="w-36">
                <StaticImage
                  src="../images/smart-property-investment-logo.png"
                  alt="Smart Property Investment logo."
                  objectFit="contain"
                  className="w-full h-full"
                />
              </div>
            </div>
            <div className="flex flex-col justify-between">
              <div className="h-full mb-4">
                <StaticImage
                  src="../images/lynette-manciameli.jpg"
                  className="w-full h-full"
                  objectPosition="70% 50%"
                  alt="A photo of Lynette Manciameli at the docks in business attire."
                />
              </div>
              <iframe
                title="Podcast Player"
                src="//html5-player.libsyn.com/embed/episode/id/15323975/height/90/theme/custom/thumbnail/yes/direction/backward/render-playlist/no/custom-color/000000/"
                height="90"
                width="100%"
                scrolling="no"
                allowfullscreen
                webkitallowfullscreen
                mozallowfullscreen
                oallowfullscreen
                msallowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
